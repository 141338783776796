import "../style/Main.scss";
import AddDeckList from "../components/AddDecklist/AddDecklist";

const Home = () => {
	return (
		<main role="main">
			<AddDeckList />
		</main>
	)
}

export default Home;